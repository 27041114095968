<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="volunteer"
        :loading="loading"
        :items-per-page="5"
      >
        <template v-slot:top> </template>
        <template v-slot:[`item.nomor`]="{ item }">
          {{
            volunteer
              .map(function (x) {
                return x.vol_id;
              })
              .indexOf(item.vol_id) + 1
          }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

export default {
  data: () => ({
    session: "",
    search: "",
    loading: true,

    volunteer: [],

    headers: [
      {
        text: "No",
        value: "nomor",
        width: "50px",
        align: "center",
        sortable: false,
      },
    //   { text: "NIK Volunteer", align: "start", value: "vol_nik" },
      { text: "Nama Volunteer", align: "start", value: "vol_nama" },
      { text: "Telpon", align: "start", value: "vol_tlp" },
      { text: "Kecamatan", align: "start", value: "kec_nama" },
      { text: "Kelurahan", align: "start", value: "kel_nama" },
    //   { text: "TPS", align: "start", value: "tps_nama" },
    //   { text: "Sumber Data", align: "start", value: "vol_refdata" },
    ],
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getVol();
  },

  methods: {
    getVol() {
      let url = process.env.VUE_APP_API + "volunteer/all";

      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((res) => {
          this.volunteer = res.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
  },
};
</script>
