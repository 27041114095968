<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import Cookie from "@/helper/cookie.js";

import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    session: "",
    chartData: {},

    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
    },
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.getData();
  },

  methods: {
    getData() {
      this.axios
        .get(process.env.VUE_APP_API + "kecamatan/vol", {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((res) => {
          let label = res.data.data.map((row) => row.kec_nama);

          this.chartData = {
            labels: label,
            datasets: [
              {
                label: "Volunteer",
                backgroundColor: "#E4003A",
                data: res.data.data.map((row) => row.jumlah),
              },
            ],
          };
        })
        .catch((error) => {
          console.log(error.response.status);
        });
    },
  },
};
</script>
