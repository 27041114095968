<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-img src="@/assets/caleg/calon.jpg"></v-img>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="mb-5 pa-5" color="utama" dark>
          <h2 class="font-weight-light">SEBARAN POTENSI VOLUNTEER</h2>
          <h1>IDRIS RAHIM - ANDI ILHAM</h1>
        </v-card>

        <v-card class="mb-5 pa-5" color="utama" dark>
          <v-img src="@/assets/logo/koalisi.png"></v-img>

          <v-divider class="my-3"></v-divider>
          <div class="text-center">
            <h3 class="font-weight-light">TOTAL POTENSI VOLUNTEER</h3>
            <h1>{{ volunteer }}</h1>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <div>
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="pa-2" flat>
            <Cbar />
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <Cvolunteer />
        </v-col>
      </v-row>
    </div>

    <v-divider class="my-12"></v-divider>

    <div style="height: 70vh" v-if="center">
      <l-map
        style="height: 100%; width: 100%; z-index: 1"
        :zoom="zoom"
        :center="center"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        @update:bounds="boundsUpdated"
      >
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-geo-json
          :geojson="geojson"
          :options="options"
          :options-style="styleFunction"
        />
        <div v-for="item in tps" :key="item.tps_id">
          <l-marker :lat-lng="item.marker">
            <l-popup>
              <div class="text-center">{{ item.tps_nama }}</div>
              <v-divider class="my-1"></v-divider>
              <div>TOTAL PEMILIH : {{ item.tps_pemilih }}</div>
              <div>JUMLAH SUARA : {{ item.jumlah }}</div>
            </l-popup>
          </l-marker>
        </div>
      </l-map>
    </div>

    <div>
      <div class="d-flex justify-end">
        <v-switch v-model="dev" inset></v-switch>
      </div>

      <v-card class="info pa-5 mb-5 white--text" v-if="dev">
        <v-row dense>
          <v-col cols="2" class="mt-3"> Center </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="center.lat"
              label="Lat"
              outlined
              dense
              dark
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="center.lng"
              label="lng"
              outlined
              dense
              dark
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="2" class="mt-3"> Zoom </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="zoom"
              label="Zoom"
              outlined
              dense
              dark
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider class="mb-3" dark></v-divider>
        <div>Bounds: {{ bounds }}</div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getRef.js";

import Cvolunteer from "@/components/volunteer/alldata";
import Cbar from "@/components/chart/cbar";

import { latLng } from "leaflet";
import { LMap, LTileLayer, LGeoJson, LMarker, LPopup } from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LMarker,
    LPopup,

    Cvolunteer,
    Cbar,
  },

  data: () => ({
    loading: false,
    dev: false,

    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",

    attribution: "&copy; Ardin Hamzah",
    fillColor: "#f73a3b",

    tps: [],
    refKec: [],

    zoom: 0,
    center: null,
    bounds: null,

    geojson: null,

    volunteer: 0,
  }),

  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },

    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: 2,
          color: "#E4003A",
          opacity: 1,
          fillColor: fillColor,
          fillOpacity: 0.5,
        };
      };
    },

    onEachFeatureFunction() {
      return (feature, layer) => {
        // layer.bindTooltip("<div>" + feature.properties.name + "</div>", {
        //   permanent: false,
        //   sticky: true,
        // });

        layer.bindPopup(
          "<div>" +
            feature.properties.name +
            "</div> <div> Volunteer : " +
            feature.properties.jumlah +
            "</div>",
          {
            permanent: false,
            sticky: true,
          }
        );
      };
    },
  },

  async created() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.mapconfig();
  },

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.refKec = await getRef.Kec(this.session.token);
    this.getgeo();
    this.marker();
    this.statistik();
  },

  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },

    centerUpdated(center) {
      this.center = center;
    },

    boundsUpdated(bounds) {
      this.bounds = bounds;
    },

    async getgeo() {
      this.loading = true;
      let url = process.env.VUE_APP_API + "map/kelurahan";

      try {
        const res = await this.axios.get(url, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        });

        const data = await res.data.data;
        this.geojson = data;

        this.loading = false;
      } catch (err) {
        console.log(err.response);
      }
    },

    mapconfig() {
      let url = process.env.VUE_APP_API + "mapconfig";

      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((res) => {
          this.center = [res.data.data.mc_lat, res.data.data.mc_lng];
          this.zoom = res.data.data.mc_zoom;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        });
    },

    marker() {
      let url = process.env.VUE_APP_API + "map/marker";

      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((res) => {
          let arr = res.data.data;

          const fix = arr.map((value) => ({
            tps_id: value.tps_id,
            jumlah: value.jumlah,
            tps_nama: value.tps_nama,
            tps_slug: value.tps_slug,
            tps_lat: JSON.parse(value.tps_lat),
            tps_lng: JSON.parse(value.tps_lng),
            tps_koordinat: value.tps_koordinat,
            tps_pemilih: value.tps_pemilih,
            marker: latLng(value.tps_lat, value.tps_lng),
            kec_id: value.kec_id,
            kel_id: value.kel_id,
          }));

          this.tps = fix;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        });
    },

    statistik() {
      let url = process.env.VUE_APP_API + "statistik/volunteer";

      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((res) => {
          this.volunteer = res.data.data.volunteer;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        });
    },
  },
};
</script>
